import React from 'react';
import { graphql } from 'gatsby';
//@ts-ignore
import * as styles from './highlighted-content-block.module.css';
import RichText from '../Global/RichText';
import CtaLink from '../Global/CtaLink';
//@ts-ignore
import { BuildLinkHref } from '../Global/BuildLinkHref';
import {
    CTAConfiguration,
    GeneralImages,
    KontentRadioMultiChoice,
    BrandColor,
    KontentRichText,
    KontentStringValue,
} from '../../../types/KontentProps';
import { getColor, getMediaData, getMultiChoice } from '../Global/DataUtils';
import PlainTextElement from '../Global/Headlines';

type ImagePositionType = 'right' | 'left' | undefined;
type LinkImg = 'yes' | 'no' | undefined;
interface Props extends CTAConfiguration, GeneralImages {
    headline: KontentRichText;
    paragraph?: KontentRichText;
    subheadline?: KontentRichText;
    image_position?: KontentRadioMultiChoice<ImagePositionType>;
    link_image?: KontentRadioMultiChoice<LinkImg>;
    content_panel__content_panel_color?: BrandColor;
    content_panel__content_panel_font_color?: BrandColor;
    hide_cta: {
        value: [
            {
                codename: string;
            }
        ];
    };
    cta_configuration__cta_location_anchor_point: KontentStringValue;
}

const HighlightedContentBlock: React.FC<Props> = ({
    content_panel__content_panel_color,
    content_panel__content_panel_font_color,
    cta_configuration__cta_label,
    cta_configuration__cta_location,
    cta_configuration__cta_location_anchor_point,
    cta_configuration__cta_window_action,
    general_large___small_image_selection__large,
    general_large___small_image_selection__small,
    headline,
    image_position,
    link_image,
    paragraph,
    subheadline,
    hide_cta,
}) => {
    const hideCta = hide_cta.value[0]?.codename;
    const largeBgImage = getMediaData(
        general_large___small_image_selection__large
    );
    const smallBgImage = getMediaData(
        general_large___small_image_selection__small
    );
    const imagePosition = getMultiChoice(image_position);
    const imgLinked = getMultiChoice(link_image) === 'yes';
    const imagePositionCase = imagePosition
        ? imagePosition.toLowerCase()
        : null;
    const ctaWindowAction = getMultiChoice(
        cta_configuration__cta_window_action
    );
    const contentPanelColor = getColor(content_panel__content_panel_color);
    const contentPanelFontColor = getColor(
        content_panel__content_panel_font_color
    );
    const largeBgImageUrl = largeBgImage?.url;
    const smallBgImageUrl = smallBgImage?.url;
    let imageContentPosition = setInnerContentPosition(imagePositionCase);
    const ctaHref = cta_configuration__cta_location_anchor_point?.value
        ? BuildLinkHref(cta_configuration__cta_location) +
          '/#' +
          cta_configuration__cta_location_anchor_point?.value
        : BuildLinkHref(cta_configuration__cta_location);

    const ctaTarget = ctaWindowAction === 'new_window' ? '_blank' : undefined;

    const imgContent = largeBgImageUrl ? (
        <picture className={styles.picture}>
            {smallBgImageUrl && (
                <source media="(max-width:480px)" srcSet={smallBgImageUrl} />
            )}
            <img src={largeBgImageUrl} alt={largeBgImage?.description} />
        </picture>
    ) : null;

    return (
        <div>
            <div className={styles.imageTextArea + ' ' + imageContentPosition}>
                <div className={styles.pictureBody}>
                    {imgLinked &&
                    cta_configuration__cta_location?.value?.[0]?.elements?.slug
                        ?.url ? (
                        <a href={ctaHref} target={ctaTarget}>
                            {imgContent}
                        </a>
                    ) : (
                        imgContent
                    )}
                </div>
                {contentPanelColor && contentPanelFontColor && (
                    <div
                        className={styles.contentBox}
                        style={{
                            backgroundColor: contentPanelColor,
                            color: contentPanelFontColor,
                        }}
                    >
                        <div className={styles.infoItems}>
                            {headline?.value && (
                                <div className={styles.headline}>
                                    <h2>
                                        <PlainTextElement text={headline} />
                                    </h2>
                                </div>
                            )}

                            {subheadline?.value && (
                                <div className={styles.subheadline}>
                                    {RichText(subheadline)}
                                </div>
                            )}

                            {paragraph?.value && (
                                <div className={styles.paragraph}>
                                    {RichText(paragraph)}
                                </div>
                            )}
                            {hideCta !== 'yes' && (
                                <div className={styles.ctaContainer}>
                                    <CtaLink
                                        href={ctaHref}
                                        target={ctaTarget}
                                        visualStyle="outlineLight"
                                        color={contentPanelFontColor}
                                    >
                                        {cta_configuration__cta_label?.value}
                                    </CtaLink>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
export default HighlightedContentBlock;

export const fragmentHighlightContentBlock = graphql`
    fragment HighlightContentBlock on kontent_item_component___highlight_content_block {
        elements {
            content_panel__content_panel_color {
                value {
                    ... on kontent_item_list___brand_colors {
                        id
                        elements {
                            color_hex_value {
                                value
                            }
                            color_name {
                                value
                            }
                        }
                    }
                }
            }
            content_panel__content_panel_font_color {
                value {
                    ... on kontent_item_list___brand_colors {
                        id
                        elements {
                            color_hex_value {
                                value
                            }
                            color_name {
                                value
                            }
                        }
                    }
                }
            }
            cta_configuration__cta_label {
                value
            }
            cta_configuration__cta_location {
                ...kontentCtaLocation
            }
            cta_configuration__cta_location_anchor_point {
                value
            }
            cta_configuration__cta_window_action {
                value {
                    codename
                }
            }
            general_large___small_image_selection__large {
                value {
                    ... on kontent_item_media___image {
                        id
                        elements {
                            caption {
                                value
                            }
                            file {
                                value {
                                    url
                                }
                            }
                        }
                    }
                }
            }
            general_large___small_image_selection__small {
                value {
                    ... on kontent_item_media___image {
                        id
                        elements {
                            caption {
                                value
                            }
                            file {
                                value {
                                    url
                                }
                            }
                        }
                    }
                }
            }
            headline {
                value
            }
            image_position {
                value {
                    codename
                }
            }
            link_image {
                value {
                    codename
                }
            }
            paragraph {
                value
            }
            subheadline {
                links {
                    codename
                }
                value
            }
            hide_cta {
                value {
                    codename
                }
            }
        }
    }
`;

function setInnerContentPosition(imagePositionCase: ImagePositionType) {
    if (imagePositionCase == 'left') {
        return styles.imageLeft;
    }

    if (imagePositionCase == 'right') {
        return styles.imageRight;
    }

    return {};
}
